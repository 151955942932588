import { Component, OnInit,TemplateRef } from '@angular/core';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { FormBuilder, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { AuthService } from 'src/app/services/auth.service';
import { Print } from 'src/app/services/Print.service';
import { ApisessionService } from 'src/app/services/apisession.service';
import { RechargeReportResp } from 'src/app/enums/apiResponse';
import { RespCode, RespTranCode,APIUrl } from 'src/app/enums/emums';
import { ApidataService } from 'src/app/services/apidata.service';
import { BsModalRef, BsModalService  } from 'ngx-bootstrap/modal';
import { NumberToWords } from 'src/app/services/NumberToWords.service';
import {RefundRequestReq,RechargeReportReq,AppDMTRecieptReq } from 'src/app/enums/apiRequest';

@Component({
  selector: 'aditya-dmtreport',
  templateUrl: './dmtreport.component.html',
  styleUrls: ['./dmtreport.component.css']
})
export class DmtreportComponent implements OnInit {
  private total=0;    
  private value; 
  AllTotalInWods: any;
  SearchForm: FormGroup;
  colorTheme = 'theme-dark-blue';
  FromDate=new Date();
  SearchResp:RechargeReportResp;
  SearchData:any;
  Domain='';
  ToDate=new Date();
  FromConfig: Partial<BsDatepickerConfig>;
  ToConfig: Partial<BsDatepickerConfig>;
  IsSearchOpen=true;
  IsReportLoaded=false;
  DmtTranReceipt: any;
  SuccessFailPopupView:BsModalRef;
  IsMobile=false;
  config = {
    keyboard: false,
    ignoreBackdropClick: true
  };
  constructor(private formBuilder: FormBuilder,private apiData:ApidataService,
     private authService:AuthService,private apiSession:ApisessionService,private print:Print,private num2word: NumberToWords,private modalService: BsModalService,) { 
    this.IsMobile=apiData.checkIsMobile();
    this.FromConfig = Object.assign({}, {maxDate: this.FromDate, containerClass: this.colorTheme,dateInputFormat: 'DD MMM YYYY',adaptivePosition: true });
    this.ToConfig = Object.assign({}, { maxDate: this.FromDate, containerClass: this.colorTheme,dateInputFormat: 'DD MMM YYYY',adaptivePosition: true });
  }

  ngOnInit() {
    this.Domain=APIUrl.Domain;
    this.FromDate.setDate(this.FromDate.getDate());
    this.SearchForm = this.formBuilder.group({
      fromdate: null,
      todate:null
    });
    this.Search();
  }

  toggleSearch()
  {
    if(this.IsSearchOpen)
    {
      this.IsSearchOpen=false;
    }
    else
    {
      this.IsSearchOpen=true;
    }
  }
  addDays(theDate, days) {
    return new Date(theDate.getTime() + days * 24 * 60 * 60 * 1000);
  }
  onValueChange(value: Date): void {
    if (value) {
      this.FromDate=value;
      var isafter = moment(this.ToDate).isAfter(value);
      if (!isafter) {
        value = this.addDays(value, 1);
        this.ToDate = value;
        this.ToConfig = Object.assign({}, { minDate: value, containerClass: this.colorTheme,dateInputFormat: 'DD MMM YYYY'  });
      }
    }
  }
  Search()
  {
    var req:RechargeReportReq=
    {
      isRecent:false,
      fromDate:moment(this.FromDate).format("DD MMM YYYY"),
      toDate:moment(this.ToDate).format("DD MMM YYYY"),
      accountNo:'',
      apiid:0,
      criteriaID:0,
      transactionID:'',
      isExport:false,
      oid:0,
      opTypeID:14,
      status:0,
      topRows:100
    }
    this.IsReportLoaded=false;
    this.apiSession.GetTopfive(req).subscribe(resp=>{
      console.log(resp);
      this.SearchResp=resp;
      if(this.SearchResp.statuscode==RespCode.Success)
      {
        this.SearchData=this.SearchResp.rechargeReport;
        this.IsReportLoaded=true;
        this.toggleSearch();
      }
      else
      {
        this.IsReportLoaded=true;
      }      
    })
  }
  onClickComplaint(t)
  {
    var req:RefundRequestReq={
      isResend:false,
      otp:"",
      tid:t.tid,
      transactionID:t.transactionID
    }
    this.apiSession.RefundRequest(req).subscribe(resp=>{
      if(resp.statuscode==RespTranCode.Success)
      {
        this.Search();
      }
      else
      {
      }
    })
  }
  getReceipt(template: TemplateRef<any>,groupID:string){
    let dMTRecieptReq:AppDMTRecieptReq = {GroupID:groupID} as AppDMTRecieptReq;
    this.apiSession.DMTReceipt(dMTRecieptReq).subscribe(resp=>{
   
      if(resp.statuscode==1)
      {
        this.DmtTranReceipt=resp.transactionDetail;
        this.findsum(resp.transactionDetail.lists);
      }
  })
    this.SuccessFailPopupView = this.modalService.show(template,this.config);
    this.SuccessFailPopupView.setClass('modal-lg');
  }
  findsum(data){ 
    this.value=data  
    for(let j=0;j<data.length;j++){   
    this.total+= this.value[j].requestedAmount
     }  
       this.AllTotalInWods=this.num2word.toWords(this.total.toString());
   } 
   printmodal()
   {
     // printData("Id Of Printind div","External csslink ");
     this.print.printData("printDiv","");
   }
   public closePrintModal() { 
    this.SuccessFailPopupView.hide();
    this.SuccessFailPopupView = null;
  }
}
