import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})


export class Print {
  styles:string=`
   
  @@media print {
    html, body {
        height: 99%;
    }

    .print:last-child {
        page-break-after: avoid;
        page-break-inside: avoid;
        margin-bottom: 0
    }
}

#invoice {
    padding: 30px;
}

.qty {
    text-align: right;
}

.invoice {
    position: relative;
    background-color: #FFF;
    min-height: 680px;
    padding: 15px;
}

    .invoice header {
        padding: 10px 0;
        margin-bottom: 20px;
        border-bottom: 3px solid #3989c6;
    }

    .invoice .company-details {
        text-align: right;
    }

        .invoice .company-details .name {
            margin-top: 0;
            margin-bottom: 0;
        }

    .invoice .contacts {
        margin-bottom: 20px;
    }

    .invoice .invoice-to {
        text-align: left;
    }

        .invoice .invoice-to .to {
            margin-top: 0;
            margin-bottom: 0;
        }

    .invoice .invoice-details {
        text-align: right;
    }

        .invoice .invoice-details .invoice-id {
            margin-top: 0;
            color: #3989c6;
        }

    .invoice main {
        padding-bottom: 50px;
    }

        .invoice main .thanks {
            margin-top: -100px;
            font-size: 2em;
            margin-bottom: 50px;
        }

        .invoice main .notices {
            padding-left: 6px;
            border-left: 6px solid #3989c6;
        }

            .invoice main .notices .notice {
                font-size: 1.2em;
            }

    .invoice table tfoot td {
        background: 0 0;
        border-bottom: none;
        white-space: nowrap;
        text-align: right;
        padding: 10px 20px;
        font-size: 1.2em;
        border-top: 1px solid #aaa;
    }

    .invoice table tfoot tr:first-child td {
        border-top: none;
    }

    .invoice table tfoot tr:last-child td {
        color: #3989c6;
        font-size: 1.4em;
        border-top: 1px solid #3989c6;
    }

    .invoice table tfoot tr td:first-child {
        border: none;
    }

    .invoice footer {
        width: 100%;
        text-align: center;
        color: #fff;
        border-top: 1px solid #aaa;
        padding: 8px 0;
    }

@@media print {
    .invoice {
        font-size: 11px !important;
        overflow: hidden !important;
    }

        .invoice footer {
            position: absolute;
            bottom: 15px;
            page-break-after: always;
        }

        .invoice > div:last-child {
            page-break-before: always;
        }
}

.head-sec {
    margin-bottom: 0 !important;
}

    .head-sec tbody tr td {
        background: none;
    }

.main-table td, th {
    border: 1px solid #aaa;
    padding: 6px 10px;
}

.date {
    font-size: 13px;
    font-weight: 500;
}

.invoice-details b {
    font-weight: 500;
}

.bank-detail div, .invoice-details div {
    font-size: 14px;
}

.cus_modal_close_btn {
    margin-right: 11px;
    position: absolute;
    z-index: 999;
    right: 10px;
    top: 10px;
    color: #222;
    opacity: 1;
    font-weight: 600;
}

.receipt_logo_top {
    position: absolute;
    right: 0;
    left: 0;
    top: 0;
}

.receipt_logo {
    text-align: center;
    display: block;
}

.notices, .thanks {
    border: 1px solid #000;
    padding: 5px;
    border-top: none;
    border-bottom: none;
}

.invoice header {
    padding: 0 !important;
    margin-top: 20px !important;
    border: 1px solid #000 !important;
    margin-bottom: 0px !important;
}

.main-table td, th {
    border: 1px solid #000 !important;
}`;
 
  
  constructor() { }
  printData(printId:string,cssPath:string="",style:string=this.styles){   
        
    let css='';

    if(cssPath!==''){
      css=`<link href="${cssPath}" rel="stylesheet" type="text/css">`;
    }
    if(style!==""){
        css=css+`<style>${style}</style>`;
    }
  var n = document.getElementById(printId||"printDiv");
    var newWin=window.open("","_blank");
      newWin.document.write(`<html>
                                <head>
                                  <title>PrintReport</title>
                                  <linkrel="stylesheet"href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css" integrity="sha384-Gn5384xqQ1aoWXA+058RXPxPg6fy4IWvTNh0E263XmFcJlSAwiGgFAW/dAiS6JXm"crossorigin="anonymous"/>
                                  <style>
                                      .bg-gray{background:#dee2e6;}
                                      @page{size:A4;margin:11mm17mm17mm17mm;}
                                      @mediaprint{footer{width:100%;position:fixed;bottom:0;}}
                                      .tabletd,.tableth{border-top:none!important}
                                    </style>
                                    ${css}
                                </head>
                                <body>`);
      newWin.document.write('<button id="btnPrint"onClick="window.print();"style="display:none">Print</button>');
      newWin.document.write(n.outerHTML);
      newWin.document.write('<script>(()=>{document.getElementById("btnPrint").click();window.close()})();</script>');
      newWin.document.write("</body></html>");
    }
       
}