import { Component, enableProdMode } from '@angular/core';
import { HeaderInfo, ThemeType } from './enums/emums';
import {Title} from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title =HeaderInfo.Title;
  public static themeType=ThemeType.Old;
  
  constructor(private titleService:Title){}

  ngOnInit(){
    if (environment.production) {
      console.log("cvvvre"+location.protocol);
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
      console.log = function (): void { };
      enableProdMode();
    }
    this.titleService.setTitle(this.title);
  }
}
